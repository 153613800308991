import * as React from "react"
import {Link} from "gatsby"


// Comonent library
import 'semantic-ui-css/semantic.min.css' // Semantic UI component library: https://react.semantic-ui.com/
//// see https://react.semantic-ui.com/layouts/fixed-menu/ 
//// and https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/src/layouts/FixedMenuLayout.js for example
import { 
  Container,
  // Divider,
  // Dropdown,
  // Grid,
  Header,
  Image,
  // List,
  // Menu,
  // Segment,
  // Checkbox,
} from 'semantic-ui-react'

import HeaderBar from '../components/HeaderBar.js'
import FooterBar from '../components/FooterBar.js'
import ContactInfo from "../components/ContactInfo.js"

// styles



// data


// markup
const ProductsPage = () => {
  return (
  <div>
  <header role="banner">
      <HeaderBar />
    </header>
    <main role="main">

    <Container text style={{ marginTop: '7em' }}>
      <Header as='h1'>Projects & Services</Header>
      <Container text>
        <Header as='h2'>Projects</Header>

        <Header as='h3'>Industry-4.0 Algae Test Lab</Header>
        <p>
          <Image alt='Industry 4.0' src='/images/i4-algae-lab-mindsphere.png' floated='left' size='small' />
          I designed and built the data-driven "Industry-4.0" microalgae bio-manufacturing facility at the University of Technology Sydney (UTS), called the Industry-4.0 Algae Test Lab. It was awarded a $2.2 million AUD grant funded by the Australian government, and became operational within 1 year from the start of construction.
        </p>
        <p>
        <Link to='https://youtu.be/5qimOVqRRjA'>Promotional video on YouTube</Link><br/>
        <Link to='https://techlab.uts.edu.au/lab/industry-4-0-testlab/'>Industry-4.0 Algae Test Lab website</Link>
        </p>

        <hr/>

        <Header as='h3'>Living Lights | Vivid Sydney</Header>
        <p>
          <Image alt='Living Lights exhibit from Vivid Sydney 2018' src='/images/living-lights1.jpg' floated='left' size='small' />
          The UTS Living Lights exhibit from the Vivid Sydney festival in 2018 was a beautiful demonstration of the diversity of microalgae and their photosynthesis. My role on the project was to design and build the user interface, so that people could light-up the algae with different colors and see how the rainbow changes when it passes through photosynthesizing cultures of red, green, and brown algae.
        </p>
        <p>
        <Link to='https://www.uts.edu.au/research/climate-change-cluster/deep-green-biotech-hub/news-and-events/news/living-lights-going-green-gold-vivid-sydney'>Living Lights website</Link>
        </p>

        <hr/>

        <Header as='h3'>Dynamic Environment Photosynthesis Imager (DEPI)</Header>
        <p>
          <Image alt='Department of Energy Plant Research Laboraroy Dynamic Environment Photosynthesis Imaging machine at Michigan State University' src='/images/DEPI.jpg' floated='left' size='small' />
          Finding the good mutation in a collection of mutant plants is like finding a needle in a haystack, but with the DEPI system, me and my colleagues at Michigan State University were able to simultaneously screen the entire haystack of plant mutants to find the few that mutated into a more efficient or robust kind of photosynthesis. These instruments, whose firmware and software I designed and developed, have been used for many years now to make cutting edge discoveries in the world of plant science.
        </p>
        <p>
        <Link to='https://prl.natsci.msu.edu/research-tech/center-for-advanced-algal-and-plant-phenotyping/depi-chambers/'>DEPI website</Link>
        </p>

        <hr/>
        
      </Container>

      <ContactInfo/>

    </Container>

    
    </main>
    <footer role="banner">
      <FooterBar />
    </footer>
    </div>
  )
}

export default ProductsPage
